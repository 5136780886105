import styled from "styled-components";

export const IntroContainer = styled.div`
    padding-top: 2rem;
    padding-bottom: 1rem;
    max-width: 765px;

    & > h1{
        font-size: 1rem;
        font-weight: 700;
        font-family: Wigrum;
    }

    & > h2{
        font-size:1.4rem;
        font-weight: 900;
        font-family: Wigrum;
    }

    & > h3{
        font-weight: 700;
        font-size: 1.25rem;
        font-family: Wigrum;
    }

    & a {
        color: #000000BB;
        font-style: italic;
        text-decoration: underline;
      }
    `;