import styled from 'styled-components';

export const JobsListingContainer = styled.div`
    margin: 0 auto;
`;

export const JobContainer = styled.div`
  position: relative; // required for z-index to take effect
  z-index: 1; // stack them in a way that they overlap well
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  margin-bottom: -1px; // this pulls the next container up to overlap

  &:first-child:is(:only-child) {
    border-bottom: 0;
  }
  
  &:last-child:is(:only-child) {
    border-top: 0;
  }

  &:only-child {
    border-top: 1px solid #e0e0e0 !important;
    border-bottom: 1px solid #e0e0e0 !important;
  }

  &:hover, &:focus {
    transition: all 150ms ease-in-out;
    border-top-color: #6d9942;
    border-bottom-color: #6d9942;
    z-index: 2; // bring the hovered/focused item to the front to ensure its borders are visible

    & svg {
      stroke: #6d9942;
      transform: scale(0.9);
    }

    & h2 {
      transition: transform 150ms ease-in-out;
      transform: translateX(10px);
    }
  }
`;



export const JobHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;
`;

export const JobDetails = styled.div`
  padding: 10px;
  display: ${props => (props.$isOpen ? 'block' : 'none')};
`;

export const JobTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;

  @media (max-width: 930px) {
    font-size: 1.3rem;
  }
`;

export const JobDetailedList = styled.ul`
    list-style: circle;
    padding-left: 20px;
    margin: 0;
`;

export const JobDetailedListItem = styled.li`
    margin: 0;
    font-size: rem;
    font-weight: 400;
    color: #777;
    text-align: left;
    text-decoration: none;
`;

export const Button = styled.button`
  font-size: 3em;
  font-weight: 400;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const Section = styled.section`
    padding: 10px;
`;

export const Description = styled.blockquote`
    font-size: 1rem;
    font-weight: 400;
    color: #333;
    text-align: left;
    text-decoration: none;
    background-color: #ebebeb;
    border-radius: 5px;
    padding: 10px;
`;

export const Offre = styled.p`
position: relative;
font-size: rem;
font-weight: 400;
color: #333;
text-align: left;
text-decoration: none;
padding: 10px;

&:before {
  border-left: 1px solid #00000050;
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 100%;
  translate: 0 -50%;
}
`;

export const Message = styled.p`
position: relative;
font-size: rem;
font-weight: 400;
color: #333;
text-align: left;
text-decoration: none;
padding: 10px;
`;