import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import { ImageCard, Image, Modal, ModalContent } from "./ImagesGrid.styles";

export default function ImagesGrid({ gallery }) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');

    const openModal = (imageSrc) => {
        setCurrentImage(imageSrc);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const breakpointColumnsObj = {
        default: 2,
        600: 1
    };

    return (
        <>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="masonry-grid"
                columnClassName="masonry-grid_column"
                
            >
                {gallery.map((imageSrc, index) => (
                    <ImageCard key={index} onClick={() => openModal(imageSrc)}>
                        <Image src={imageSrc} alt={`Gallery image ${index + 1}`} />
                    </ImageCard>
                ))}
            </Masonry>

            {isModalOpen && (
                <Modal onClick={closeModal}>
                    <ModalContent>
                        <Image loading='lazy' src={currentImage} alt="Full Screen" />
                    </ModalContent>
                </Modal>
            )}
        </>
    );
}
