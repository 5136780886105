import styled from "styled-components";

export const MapContainer = styled.div`
    position: relative;
`;

export const Map = styled.div`
    height: 50dvh;
    width: 100%;
`;
