import HeroSlider from '../Components/HeroSlider/HeroSlider';
import ProjectsSlider from '../Components/ProjectsSlider/ProjectsSlider';
import useProjects from '../Assets/Hooks/getProjects';
import LoadingComponent from "../Components/Common/LoadingComponent/LoadingComponent";
import InfoSlider from '../Components/InfoSlider/InfoSlider';
import Footer from '../Components/Footer/Footer';
import EmbedSocial from '../Components/EmbedSocial/EmbedSocial';

export default function HomePage() {
    const { projects, isLoading, error } = useProjects();
    
    if (isLoading) return <LoadingComponent />;
    if (error) return <p>Error loading job listings: {error.message}</p>;
    return (
        <>
            <HeroSlider />
            <ProjectsSlider data={projects}/>
            <InfoSlider/>
            <EmbedSocial/>

            <Footer />
        </>

    )
}