import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ArrowButton, Container, ContentCard, ContentImage } from './InfoSlider.styles';
import { InfoTitle, Separator, JustifiedText, SubTitle, BubbleLink } from '../Common/Common.styles';

import collaborationImage from '../../Assets/Images/Illustrations/Illustration_collaboration.png';
import creativityImage from '../../Assets/Images/Illustrations/Illustration_creativite.png';
import respectImage from '../../Assets/Images/Illustrations/Illustration_respect.png';
import funImage from '../../Assets/Images/Illustrations/Illustration_plaisir.png';



export default function ImageSlider() {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 3000, min: 1801 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 1800, min: 1025 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 600 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 600, min: 0 },
            items: 1,
        },
    };

    const ArrowLeft = () => (
        <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M19.92 12.48a.6.6 0 0 0-.6-.6H5.17l3.776-3.775a.601.601 0 0 0-.85-.85l-4.8 4.8a.6.6 0 0 0 0 .85l4.8 4.8a.602.602 0 0 0 .98-.655.6.6 0 0 0-.13-.195L5.169 13.08H19.32a.6.6 0 0 0 .6-.6Z" clipRule="evenodd"></path>
        </svg>
    );

    const ArrowRight = () => (
        <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M3.12 12.48a.6.6 0 0 1 .6-.6h14.152l-3.777-3.775a.6.6 0 1 1 .85-.85l4.8 4.8a.6.6 0 0 1 0 .85l-4.8 4.8a.6.6 0 1 1-.85-.85l3.777-3.775H3.72a.6.6 0 0 1-.6-.6Z" clipRule="evenodd"></path>
        </svg>
    );

    return (
        <Container>
            <InfoTitle>Notre firme</InfoTitle>
            <Separator />
            <Carousel
                className='carousel'
                swipeable={true}
                draggable={true}
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition="transform 500ms ease-in-out"
                transitionDuration={500}
                centerMode={window.innerWidth > 600 ? true : false}
                itemClass= {window.innerWidth > 600 ? 'carousel-item' : 'carousel-item-mobile'}
                customLeftArrow={<ArrowButton style={{ left: "15px" }}><ArrowLeft /></ArrowButton>}
                customRightArrow={<ArrowButton style={{ right: "15px" }}><ArrowRight /></ArrowButton>}
            >
                <ContentCard>
                    <SubTitle>Notre histoire</SubTitle>
                    <JustifiedText className='maxLines'>
                    La firme a été fondée en 1987 par l'architecte Pierre Leclerc avec la volonté d'être un acteur engagé au sein des communautés. Depuis sa fondation, le groupe soutient plusieurs causes sociales et participe à de nombreux projets architecturaux diversifiés dans les secteurs suivants : municipal, institutionnel, sportif, commercial et industriel. Son savoir-faire réunit plusieurs spécialités de la pratique dans un mode traditionnel tout comme dans un mode de clé en main.
                    </JustifiedText>
                    <BubbleLink href="/notre-firme">En savoir plus</BubbleLink>
                </ContentCard>
                <ContentCard>
                    <SubTitle>Le travail d'équipe et collaboration</SubTitle>
                    <ContentImage src={collaborationImage} alt="img" />
                    <JustifiedText className='maxLines'>
                        Être disponible et accessible afin de
                        s'écouter et s'entraider. Partager l'expertise
                        et les connaissances ...
                    </JustifiedText>
                    <BubbleLink href="/notre-firme">En savoir plus</BubbleLink>
                </ContentCard>
                <ContentCard>
                    <SubTitle>Le respect du client et l'empathie</SubTitle>
                    <ContentImage src={respectImage} alt="img" />
                    <JustifiedText className='maxLines'>
                        Maintenir la réputation de GLAD dans la
                        livraison de services qui respectent les
                        besoins et budgets de ...
                    </JustifiedText>
                    <BubbleLink href="/notre-firme">En savoir plus</BubbleLink>
                </ContentCard>
                <ContentCard>
                    <SubTitle>Notre approche</SubTitle>
                    <JustifiedText className='maxLines'>
                        
Groupe Leclerc se démarque par la versatilité de ses services et de ses atouts, mais surtout des relations sociales qu'elle crée et soutien à chaque opportunité. Le principal objectif de notre équipe d'architectes, de technologues et de designers est de travailler en partenariat avec nos clients dans un climat de confiance et d'écoute mutuelle. Nos clients font appel à nous non seulement pour obtenir un appui professionnel dans les services traditionnels, mais également pour les aider ...
                    </JustifiedText>
                    <BubbleLink href="/notre-firme">En savoir plus</BubbleLink>
                </ContentCard>
                <ContentCard>
                    <SubTitle>Le plaisir et épanouissement</SubTitle>
                    <ContentImage src={funImage} alt="img" />
                    <JustifiedText className='maxLines'>
                        Travailler dans un environnement où
                        nous avons du plaisir, de la liberté et de
                        la flexibilité tout en rendant ...
                    </JustifiedText>
                    <BubbleLink href="/notre-firme">En savoir plus</BubbleLink>
                </ContentCard>
                <ContentCard>
                    <SubTitle>Nos Services</SubTitle>
                    <JustifiedText className='maxLines'>
                    La firme offre des services variés dans de multiples domaines de l'architecture depuis la réalisation programmes fonctionnels et techniques, la gestion de projets, la conception, préparation des plans et devis complets, l'élaboration, le design d'intérieur ainsi que la surveillance de chantiers. Elle possède également une profonde expertise dans les projets de démolition et de nouvelles constructions, d'agrandissement, de réaménagement et de réfection de bâtiments ...
                    </JustifiedText>
                    <BubbleLink href="/notre-firme">En savoir plus</BubbleLink>
                </ContentCard>
                <ContentCard>
                    <SubTitle>Le courage et la créativité</SubTitle>
                    <ContentImage src={creativityImage} alt="img" />
                    <JustifiedText className='maxLines'>
                    Ne pas avoir peur de cibler et réaliser des
projets complexes qui demandent une
bonne dose ...
                    </JustifiedText>
                    <BubbleLink href="/notre-firme">En savoir plus</BubbleLink>
                </ContentCard>
            </Carousel>
        </Container>
    );
}
