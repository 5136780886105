import { Container, ContactCard, ContactIcon, ContactCardTitle } from './ContactContainer.styles';
import { BubbleLink, RowContainer } from "../Common/Common.styles.js";

import contactPhoto from '../../Assets/Images/Illustrations/Illustration_contact.png';

export default function ContactContainer() {

    const PhoneIcon = () => {
        return (
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 18.675c1.93.83 4.242 1.325 7 1.325v-4l-4-1-3 3.675Z"></path>
                <path d="M4 4c0 1.898.4 4.472 1.5 7L9 8 8 4H4Z"></path>
                <path d="M13 18.675C9.159 17.023 6.824 14.045 5.5 11"></path>
            </svg>
        )
    }

    const MailIcon = () => {
        return (
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="m7 9 3.75 3a2 2 0 0 0 2.5 0L17 9"></path>
                <path d="M21 7v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2Z"></path>
            </svg>
        )
    }

    return (
        <RowContainer>
            <img loading="lazy" src={contactPhoto} alt="contact" style={{ width: "500px", height: "auto" }} />
            <Container>
                <ContactCard>
                    <ContactIcon>{PhoneIcon()}</ContactIcon>
                    <ContactCardTitle>Pour nous joindre par téléphone</ContactCardTitle>
                    <BubbleLink href="tel:(450)443-4130">450.443.4130</BubbleLink>
                </ContactCard>
                <ContactCard>
                    <ContactIcon>{MailIcon()}</ContactIcon>
                    <ContactCardTitle>Pour les offres de services</ContactCardTitle>
                    <BubbleLink href="mailto:info@groupeleclerc.ca">info@groupeleclerc.ca</BubbleLink>
                </ContactCard>
                <ContactCard>
                    <ContactIcon>{MailIcon()}</ContactIcon>
                    <ContactCardTitle> Pour les offres d'emploi</ContactCardTitle>
                    <BubbleLink href="direction@groupeleclerc.ca">direction@groupeleclerc.ca</BubbleLink>
                </ContactCard>
                <ContactCard>
                    <ContactIcon>{MailIcon()}</ContactIcon>
                    <ContactCardTitle>Pour les fournisseurs et représentants</ContactCardTitle>
                    <BubbleLink href="materiautheque@groupeleclerc.ca">materiautheque@groupeleclerc.ca</BubbleLink>
                </ContactCard>
            </Container>
        </RowContainer>
    )
}