import { Title, Text, TextContainer, Separator } from '../Components/Common/Common.styles';
import PageIntro from '../Components/Common/PageIntro/PageIntro';
import Footer from '../Components/Footer/Footer';
import SideBySideBlock from "../Components/SideBySideBlock/SideBySideBlock"
import { Helmet } from 'react-helmet';

export default function PrivacyPolicy() {
    return (
        <>
            <Helmet>
                <title>GroupeLeclerc | Politique de Confidentialité</title>
                <meta name='description' content="La page de Politique de confidentialité du GroupeLeclerc" />
                <meta name='keywords' content="Politique de confidentialité" />
            </Helmet>

            <PageIntro>
                <Title>Politique de confidentialité</Title>
                <br />
                <Text>Groupe Leclerc architecture + design Inc., société opératrice du site Internet Groupe Leclerc architecture + design Inc., s'engage à respecter la vie privée de ses utilisateurs. Cette politique de confidentialité a pour but d'exposer comment sont traités les renseignements personnels recueillis par le biais de notre site web à l'adresse suivante <a href='http://groupeleclerc.net'>groupeleclerc.net</a> et <a href='http://groupeleclerc.ca'>groupeleclerc.ca</a>. En nous fournissant vos informations personnelles ou en utilisant notre Site Web, vous consentez à être lié par la présente politique.</Text>
            </PageIntro>
            <Separator />
            <TextContainer>
                <SideBySideBlock title="Collecte des renseignements personnels">
                    <Text>Nous collectons les informations personnelles suivantes via nos formulaires:</Text>
                    <ul>
                        <li>Prénom</li>
                        <li>Nom</li>
                        <li>Adresse électronique</li>
                        <li>Numéro de téléphone</li>
                    </ul>
                    <Text>Les renseignements personnels que nous collectons sont recueillis au travers de formulaires. Nous utilisons également, comme indiqué dans la section suivante, des fichiers témoins (cookies) pour réunir des informations vous concernant.</Text>
                </SideBySideBlock>

                <SideBySideBlock title="Formulaires et interactivité">
                    <Text>Vos renseignements personnels sont collectés par le biais de formulaires, à savoir:</Text>
                    <ul>
                        <li>Formulaire de contact</li>
                        <li>Demande d'emploi</li>
                    </ul>
                    <Text>Nous utilisons les renseignements ainsi collectés pour les finalités suivantes:</Text>
                    <ul>
                        <li>Informations</li>
                        <li>Statistiques</li>
                        <li>Contact</li>
                    </ul>
                </SideBySideBlock>

                <SideBySideBlock title="Données recueillies automatiquement">
                    <Text>Nous recueillons également de l'information de manière automatique via des fichiers témoins (cookies) et d'autres dispositifs technologiques pour les fins suivantes:</Text>
                    <ul>
                        <li>Bon fonctionnement du site web</li>
                        <li>Faciliter la navigation</li>
                        <li>Analyser les données de navigation</li>
                        <li>Mesurer la performance</li>
                    </ul>
                </SideBySideBlock>

                <SideBySideBlock title="Droit d'opposition et de retrait">
                    <Text>Nous nous engageons à vous offrir un droit d'opposition et de retrait quant à vos renseignements personnels. Le droit d'opposition s'entend comme la possibilité offerte de refuser que vos renseignements personnels soient utilisés pour certaines fins. Vous pouvez exercer ce droit en nous contactant.</Text>
                </SideBySideBlock>

                <SideBySideBlock title="Sécurité">
                    <Text>Les renseignements personnels que nous collectons sont conservés dans un environnement sécurisé. Nous prenons toutes les précautions nécessaires pour protéger vos informations.</Text>
                </SideBySideBlock>

                <SideBySideBlock title="Modifications de la politique de confidentialité">
                    <Text>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment, avec effet immédiat après la publication de la version modifiée sur notre site web.</Text>
                </SideBySideBlock>

                <SideBySideBlock title="Contact et traitement des plaintes">
                    <Text>Pour toute question ou plainte concernant cette politique de confidentialité ou nos pratiques de traitement des données, vous pouvez nous contacter par courriel à <a href='mailto:info@groupeleclerc.ca'>info@groupeleclerc.ca</a>.</Text>
                </SideBySideBlock>

            </TextContainer>
            <Footer />
        </>
    );
}
