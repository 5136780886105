import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0;
    `;

export const ContentContainer = styled.div`
    width: 100%;
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;

    @media (max-width: 1080px) {
         grid-template-columns: repeat(2, 1fr);
          padding: 0 1rem;
          gap: 2rem;
      }
    
    @media (max-width: 960px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 0 1rem;
        gap: 2rem;
    }
`;

export const ContentCard = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;

    & svg, & img {
        height: 150px;
        margin-bottom: 1rem;
    }

    & h3{
        font-size: 1.1rem;
        font-weight: 400;
        text-align: center;
        height: 40px;
    }
`;