import { Helmet } from "react-helmet"
import MapboxMap from "../Components/MapboxMap/MapboxMap"

import { Title, SubTitle } from "../Components/Common/Common.styles";
import PageIntro from "../Components/Common/PageIntro/PageIntro";
import Footer from "../Components/Footer/Footer";
import ContactContainer from "../Components/ContactContainer/ContactContainer";

export default function ContactPage() {

    return (
        <>
            <Helmet>
                <title>GroupeLeclerc | Contact</title>
                <meta name='description' content="La page de contact du GroupeLeclerc" />
                <meta name='keywords' content="Architecture, Design, Développement, Emploi, Job, Offre, Stage, Projet, Recrutement" />
            </Helmet>

            <MapboxMap />
            <PageIntro>
                <Title>
                Y'a rien de mieux que de se rencontrer avec un bon café pour en jaser,
                </Title>
                <SubTitle>
                écris-nous!
                </SubTitle>
            </PageIntro>

            <ContactContainer />

            <Footer />
        </>
    )
} 