import styled from "styled-components";

export const HeaderContainer = styled.header`
    height: var(--header-height);
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;    
    justify-content: space-between;
    gap: 1rem;
    padding: 0 13rem;

    @media screen and (max-width: 1450px) {
            padding: 0 7rem;
    }

    @media screen and (max-width: 930px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }
`;

export const HeaderLogo = styled.img`
    position: relative;
    height: 3rem;
    width: auto;
    filter: grayscale(100%) brightness(0%);
    z-index: 1000;

    @media screen and (max-width: 930px) {
        height: 2.4rem;
        margin-left: 35px;
        margin-top: 35px;
    }
`;

export const HeaderNav = styled.nav`
    display: flex;
    align-items: center;
    gap: 1rem;

    @media screen and (max-width: 930px) {
        translate: ${props => props.$hide ? '0 0' : '0 -150%'};
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        z-index: 999;
        background-color: #FFFFFF;
        padding: 35px 0;
        width: 100%;
    }
`;

export const NavItems = styled.li`
    list-style: none;
    font-size: 1rem;
    font-weight: 400;

    & .active {
        color: #020202;
        text-decoration: underline;
        text-underline-offset: 0.5rem;
        font-weight: 600;
    }

    &:hover, &:focus {
        color: #00000080;
        text-decoration: underline;
        text-underline-offset: 0.5rem;
        text-decoration-thickness: 0.1rem;
        text-decoration-color: #00000020;
    }
`;

export const MobileMenu = styled.button`
    display: none;
    background: transparent;
    color: white;
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    & svg {
        height: 2rem;
        width: auto;
        fill: #020202;
    }

    &:active {
        transform: scale(0.95);
    }

    @media screen and (max-width: 930px) {
        right: 35px;
        position: absolute;
        top: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    z-index: 1000;
`;