import styled from "styled-components";

export const ProjectsGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;

    @media (max-width: 1450px) {
        grid-template-columns: repeat(3, 1fr);
        padding: 0;
    }

    @media (max-width: 930px) {
        grid-template-columns: repeat(2, 1fr);
        padding: 0;
    }

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        padding: 0;
    }
`;

export const ProjectCard = styled.a`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;

    &:hover, &:focus {
        & h2{
            transition: all 0.3s ease-in-out;
            text-align: center;
            opacity: 1;
            width: 90%;
        }

        & img {
            transition: all 0.2s ease-in-out;
            filter: brightness(0.5) contrast(0.8); 
        }

        & div {
            transition: all 0.2s ease-in-out;
            transform: translateY(-30px);
            opacity: 0;
        }
    }
`;

export const ProjectImage = styled.img`
    width: inherit;
    object-fit: cover;
    display: block;
    user-select: none;
    filter: grayscale(1);
    aspect-ratio: 7/5;
`;

export const ProjectTitle = styled.h2`
    position: absolute;
    bottom: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    color: white;
    font-size: 1.4rem;
    font-weight: bold;
    opacity: 0;
`;