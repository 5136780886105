import { Container, ContentContainer, ContentCard } from './ValuesContainer.styles';
import { SmallJustifiedText, SubTitle } from '../Common/Common.styles';

import collaborationImage from '../../Assets/Images/Illustrations/Illustration_collaboration.png';
import creativityImage from '../../Assets/Images/Illustrations/Illustration_creativite.png';
import respectImage from '../../Assets/Images/Illustrations/Illustration_respect.png';
import funImage from '../../Assets/Images/Illustrations/Illustration_plaisir.png';

export default function ValuesContainer() {
    return (
        <Container>
            <ContentContainer>
                <ContentCard>
                    <img loading="lazy" src={collaborationImage} alt="Collaboration" />
                    <SubTitle>La collaboration <br /><br /></SubTitle>
                    <SmallJustifiedText>
                        Être disponible et accessible afin
                        de s'écouter et s'entraider.
                        Partager l'expertise et les
                        connaissances. S'encourager
                        mutuellement dans toute
                        l'entreprise.
                    </SmallJustifiedText>
                </ContentCard>
                <ContentCard>
                    <img loading="lazy" src={funImage} alt="fun" />
                    <SubTitle>Le plaisir et l'épanouissement </SubTitle>
                    <SmallJustifiedText>
                        Travailler dans un environnement où
                        nous avons du plaisir, de la liberté et
                        de la flexibilité tout en offrant des
                        services professionnels en ligne
                        avec les critères de qualité de
                        l'entreprise.
                    </SmallJustifiedText>
                </ContentCard>
                <ContentCard>
                    <img loading="lazy" src={respectImage} alt="respect" />
                    <SubTitle>Le respect du client et l'empathie</SubTitle>
                    <SmallJustifiedText>
                        Maintenir la réputation de GLAD
                        dans la livraison de services en
                        respectant les besoins et le budget
                        de la clientèle et en ne perdant pas
                        de vue les gens qui vont
                        habiter/exploiter le bâtiment.
                    </SmallJustifiedText>
                </ContentCard>
                <ContentCard>
                    <img loading="lazy" src={creativityImage} alt="creative" />
                    <SubTitle>Le courage et la créativité <br /><br /></SubTitle>
                    <SmallJustifiedText>
                        Ne pas avoir peur de cibler et réaliser des projets complexes qui demandent une bonne dose d'innovation et de créativité.
                    </SmallJustifiedText>
                </ContentCard>
            </ContentContainer>

        </Container>
    )
}