import { Helmet } from "react-helmet"
import SideBySideBlock from "../Components/SideBySideBlock/SideBySideBlock"
import ValuesContainer from "../Components/ValuesContainer/ValuesContainer";
import Footer from "../Components/Footer/Footer";
import { Title,JustifiedText } from "../Components/Common/Common.styles";
import PageIntro from "../Components/Common/PageIntro/PageIntro";

import aboutUsImage from '../Assets/Images/Illustrations/Illustration_firme.png';

export default function AboutUsPage() {
    return (
        <>
            <Helmet>
                <title>GroupeLeclerc | Notre Firme</title>
                <meta name='description' content="Page de description de la frime de l'entreprise GroupeLeclerc." />
                <meta name='keywords' content="Architecture, Design, Développement, Emploi, Job, Offre, Stage, Projet, Recrutement" />
            </Helmet>

            <div>
                <img loading="lazy" src={aboutUsImage} alt="Notre firme" />
                <PageIntro>
                    <Title>Architecture et design depuis 1987</Title>
                </PageIntro>
                <SideBySideBlock title="Notre histoire">
                    <JustifiedText>
                    La firme a été fondée en 1987 par l'architecte Pierre Leclerc avec la volonté d'être un acteur <strong>engagé au sein des communautés</strong>. Depuis sa fondation, le groupe soutient plusieurs <strong>causes sociales</strong> et participe à de nombreux projets architecturaux diversifiés dans les secteurs suivants : <strong>municipal, institutionnel, sportif, commercial et industriel</strong>.  Son savoir-faire réunit plusieurs spécialités de la pratique dans un mode traditionnel tout comme dans un mode de clé en main.
                    <br /><br />
                    Composé d'une vingtaine d'employés motivés par le désir d'<strong>innover</strong> et encouragés à révolutionner l'approche de l'architecture, l'ambiance et la culture de l'entreprise se veulent <strong>collaboratives, stimulantes</strong> et guidées par le <strong>plaisir et la créativité</strong>.
                    </JustifiedText>
                </SideBySideBlock>
                <SideBySideBlock title="Nos services">
                    <JustifiedText>
                    La firme offre des services variés dans de multiples domaines de l'architecture depuis la réalisation de <strong>programmes fonctionnels et techniques</strong>, la <strong>gestion de projet</strong>, la <strong>conception</strong>, la préparation des <strong>plans et devis</strong> complets, le <strong>design d'intérieur</strong> ainsi que la <strong>surveillance de chantiers</strong>. Elle possède également une profonde expertise dans les projets de démolition et de nouvelles constructions, d'agrandissement, de réaménagement et de réfection de bâtiments à vocation publique.
                    </JustifiedText>
                </SideBySideBlock>
                <SideBySideBlock title="Notre approche">
                    <JustifiedText>
                    Groupe Leclerc se démarque par la <strong>versatilité de ses services</strong>, mais surtout par les <strong>relations sociales</strong> qu'elle crée et soutien à chaque opportunité. Le principal objectif de notre équipe d'architectes, de technologues et de designers est de <strong>travailler en partenariat</strong> avec nos clients dans un climat de <strong>confiance</strong> et d'<strong>écoute mutuelle</strong>. Nos clients font appel à nous non seulement pour obtenir un appui professionnel dans les services traditionnels, mais également pour les aider à réaliser des projets et des <strong>solutions durables, fonctionnelles et caractérisées par les défis, l'innovation</strong> et la <strong>créativité</strong>
                    </JustifiedText>
                </SideBySideBlock>
                <SideBySideBlock title="Nos valeurs">
                    <ValuesContainer />
                </SideBySideBlock>
                <SideBySideBlock title="Nos ++">
                    <ul>
                        <li>Une conciliation travail et vie personnelle via nos <strong>semaines de 4 jours à l'année</strong> + le <strong>télétravail</strong> optionnel les lundis</li>
                        <li>Une <strong>flexibilité</strong> dans nos horaires de travail pour les lève-tôt autant que pour les lève-tard</li>
                        <li>Un budget pour chaque employé qui <strong>promeut la forme physique</strong>, que ce soit en équipements ou en abonnements</li>
                        <li>La possibilité de travailler sur des <strong>projets diversifiés</strong> et de sortir du cadre de votre poste en fonction de vos intérêts</li>
                        <li>Une excellente ambiance de travail avec un <strong>comité social</strong> motivé et impliqué</li>
                        <li>La possibilité de faire de la <strong>formation continue</strong> sur un thème qui vous intéresse</li>
                        <li>Un <strong>régime d'assurance collective</strong></li>
                        <li>La possibilité de cotiser à une régime <strong>épargne-retraite</strong> avec participation de l'employeur</li>
                    </ul>
                </SideBySideBlock>
                <SideBySideBlock title="Prix et distinctions">
                    <ul>
                        <li>Certification <strong>LEED CI – ARGENT</strong> – 2016 : Réaménagement du bureau Groupe Leclerc architecture+design</li>
                        <li>Finaliste au <strong>Grands Prix du Design</strong> 2015 : Projet d'aménagement BMO Marchés des capitaux</li>
                        <li>Finaliste au <strong>Grands Prix du Design</strong> 2015 : Projet d'aménagement bureaux Groupe Leclerc architecture+design</li>
                        <li>Finaliste au <strong>Grands Prix du Design</strong> 2015 : Projet novateur Modules MoveHome</li>
                        <li>NOVAE 2013 : MODÉCO – Gagnant du concours <strong>ÉCOCONCEPTION</strong></li>
                        <li>Prix d'<strong>efficacité énergétique</strong> international 2011 : «INTERNATIONAL ENERGY PROJECT OF THE YEAR 2011», Agrandissement et réhabilitation des ateliers municipaux de Saint-Bruno-de-Montarville</li>
                        <li>AERMQ – <strong>Prix d'excellence</strong> 2010 : Pour leur maîtrise respective en Design et Construction utilisant un système de revêtement métallique. Agrandissement et réhabilitation des ateliers municipaux de Saint-Bruno-de-Montarville</li>
                        <li>Mention du jury : CONCOURS D'IDÉES EN <strong>DESIGN URBAIN</strong> – Pôle du Quartier Latin « La Loge »</li>
                    </ul>
                </SideBySideBlock>
                <Footer />
            </div>
        </>
    )
} 