import { Container, LeftSide, RightSide } from "./SideBySideBlock.styles";
import { Title } from "../Common/Common.styles";


export default function SideBySideBlock({ reverse, children, title }) {
    return (
        <Container reverse={reverse}>
            <LeftSide reverse={reverse}>
                <Title>{title}</Title>
            </LeftSide>
            <RightSide>
                {children}
            </RightSide>
        </Container>
    )
}