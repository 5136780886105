// sanityClient.js
import sanityClient from '@sanity/client';

const client = sanityClient({
  projectId: 'vwmwwa5v', // replace with your project ID
  dataset: 'production', // replace with your dataset
  apiVersion: '2023-10-23', // use a UTC date string
  useCdn: true, // use the CDN to fetch data, set to false if you need to use authenticated reads
});

export default client;
