import React, { useState, useEffect } from 'react';
import { HeroSliderContainer, Slide } from './HeroSlider.styles';
import useHeroSlider from '../../Assets/Hooks/getHeroSliderImages'; // Ensure the path is correct
import LoadingComponent from '../Common/LoadingComponent/LoadingComponent';

export default function HeroSlider() {
    const [current, setCurrent] = useState(0);
    const { slides, isLoading, error } = useHeroSlider();

    useEffect(() => {    
        if (slides.length === 0) return; // Guard clause to handle empty slides array

        const interval = setInterval(() => {
            setCurrent(current => (current + 1) % slides.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [slides, slides.length]); // Add slides.length to dependencies to update interval correctly when slides change
    
    if (isLoading) {
        return <LoadingComponent />;
    }

    if (error) {
        return <div>Error loading images</div>; 
    }

    return (
        <HeroSliderContainer>
            {slides.map((slide, index) => (
                <Slide 
                    key={slide.image.asset._id || index} // Use the unique ID from the image asset
                    $backgroundImage={slide.imageUrl} // Use imageUrl from the slide
                    alt={slide.altText} // Alt text for accessibility
                    style={{ 
                        opacity: index === current ? 1 : 0,
                        zIndex: index === current ? 2 : 1
                    }}
                />
            ))}
        </HeroSliderContainer>
    );
}
