import React, { useEffect } from 'react';
import { InfoTitle, Separator } from '../Common/Common.styles';

const EmbedSocial = () => {
    useEffect(() => {
        // Function to load the script
        const loadScript = () => {
            const script = document.createElement('script');
            script.src = "https://embedsocial.com/cdn/ht.js";
            script.async = true;
            document.head.appendChild(script);
        };

        // Check if the script is already loaded
        if (!document.getElementById('EmbedSocialHashtagScript')) {
            loadScript();
        }
    }, []);

    return (
        <>
            <InfoTitle>Notre instagram</InfoTitle>
            <Separator />
            <div style={{ width: "100%" }} className="embedsocial-hashtag" data-ref="e7403c8ba9065795987c93a76e79990004afa9e8"></div>
        </>
    );
};

export default EmbedSocial;
