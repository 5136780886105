import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useProject from "../Assets/Hooks/getProject";
import ProjectImage from '../Components/ProjectImage/ProjectImage';
import ProjectInformation from '../Components/ProjectInformation/ProjectInformation';
import ImageGrid from '../Components/ImageGrid/ImagesGrid';
import LoadingComponent from "../Components/Common/LoadingComponent/LoadingComponent";
import Footer from '../Components/Footer/Footer';

export default function ProjectsPage() {
    const { slug } = useParams();
    const { project, isLoading, error } = useProject(slug);

    if (isLoading) return <LoadingComponent />;
    if (error) { return <div>Error: {error.message}</div>; }
    if (!project) { return <div>No project found for {slug}</div>; }

    return (
        <>
            <Helmet>
                <title>GroupeLeclerc | {project.title} </title>
                <meta name='description' content="projet de groupe leclerc" />
                <meta name='keywords' content="Architecture, Design, Développement, Emploi, Job, Offre, Stage, Projet, Recrutement" />
            </Helmet>

            <ProjectImage image={project.imageUrl} />
            <ProjectInformation project={project} />
            <ImageGrid gallery={project.gallery} />

            <Footer />
        </>
    );
}
