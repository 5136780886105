import React, { useState } from 'react';
import { JobContainer, JobDetails, JobHeader, JobTitle, Button, JobDetailedList, JobDetailedListItem, Section, Description, Message } from "./JobsListing.styles"
import { BubbleLink, SubTitle } from "../Common/Common.styles";
export default function JobItem({ job }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDetails = () => {
    setIsOpen(!isOpen);
  };

  const openSvg = () => <svg height="2rem" fill="none" stroke="#00000050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.8" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.03 5-.018 14"></path><path d="M5 12h14"></path></svg>
  const closeSvg = () => <svg height="2rem" fill="none" stroke="#00000050" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.8" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5.25 12h14"></path></svg>

  const profil = job.profil && Array.isArray(job.profil) ? job.profil : [];
  const roles = job.roles && Array.isArray(job.roles) ? job.roles : [];
  const logiciels = job.logiciels && Array.isArray(job.logiciels) ? job.logiciels : [];
  const avantages = job.avantages && Array.isArray(job.avantages) ? job.avantages : [];

  const mail = `mailto:direction@groupeleclerc.ca?subject=Candidature%20pour%20le%20poste%20de%20${job.titre}`;

  return (
    <JobContainer>
      <JobHeader onClick={toggleDetails}>
        <JobTitle>{job.titre}</JobTitle>
        <Button>{isOpen ? closeSvg() : openSvg()}</Button>
      </JobHeader>
      <JobDetails $isOpen={isOpen}>
        <Section>
          <Description>
            {job.description}
          </Description>
        </Section>
        <Section>
          <SubTitle>Rôle et responsabilités:</SubTitle>
          <JobDetailedList>
            {roles?.map((roles, index) => (
              roles === "" ? null :
                <JobDetailedListItem key={index}>{roles}</JobDetailedListItem>
            ))}
          </JobDetailedList>
        </Section>
        <Section>
          <SubTitle>Profil recherché:</SubTitle>
          <JobDetailedList>
            {profil?.map((profil, index) => (
              profil === "" ? null :
                <JobDetailedListItem key={index}>{profil}</JobDetailedListItem>
            ))}
          </JobDetailedList>
        </Section>
        <Section>
          <SubTitle>Logiciels:</SubTitle>
          <JobDetailedList>
            {logiciels?.map((logiciels, index) => (
              logiciels === "" ? null :
                <JobDetailedListItem key={index}>{logiciels}</JobDetailedListItem>
            ))}
          </JobDetailedList>
        </Section>
        <Section>
          <SubTitle>Avantages:</SubTitle>
          <JobDetailedList>
            {avantages?.map((avantages, index) => (
              avantages === "" ? null :
                <JobDetailedListItem key={index}>{avantages}</JobDetailedListItem>
            ))}
          </JobDetailedList>
        </Section>
        <Section>
          <Message>
            {job.message}
          </Message>
          <br/>
          <BubbleLink href={mail}>
            Envoyez-nous votre candidature
          </BubbleLink>
        </Section>
      </JobDetails>
    </JobContainer>
  );
};