import { useState, useEffect } from 'react';
import client from '../../sanityClient';
import imageUrlBuilder from '@sanity/image-url';

// Set up the image URL builder
const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function useProject(slug) {
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    client
      .fetch(`*[_type == "projets" && slug == $slug]{
        title,
        slug,
        description,
        type,
        contributeurs,
        construction,
        annee,
        localisation,
        superficie,
        photographie,
        categories,
        image {
          asset-> {
            _id,
            url
          }
        },
        gallery[] {
          asset-> {
            _id,
            url
          }
        }
      }`, { slug })
      .then(data => {
        if (data && data.length > 0) {
          const projectWithImageUrl = {
            ...data[0],
            imageUrl: urlFor(data[0].image?.asset),
            gallery: data[0].gallery?.map(img => urlFor(img.asset)) || []            
          };
          setProject(projectWithImageUrl);
        } else {
          setProject(null);
        }
        setTimeout(() => {
        setIsLoading(false);
        }, 1500);
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
      });
  }, [slug]);

  return { project, isLoading, error };
}

export default useProject;
