import React, { useState } from 'react';
import {
    Container,
    ProjectTitle,
    Description,
    TitleContainer,
    InfoContainer,
    ContentContainer,
    Information,
    Item,
    ShowInfoButton,
} from './ProjectInformation.styles';
import { SubTitleSmall, Row, Col } from '../Common/Common.styles';

const ProjectInformation = ({ project }) => {
    const [isHidden, setIsHidden] = useState(true);
    return (
        <>
            <ShowInfoButton onClick={() => { setIsHidden(!isHidden) }}>{isHidden ? "Afficher l'information" : "Fermer"}</ShowInfoButton>
            <Container>
                <ContentContainer>
                    <TitleContainer>
                        <ProjectTitle>{project.title}</ProjectTitle>
                    </TitleContainer>
                    {project.description?.map((paragraph, index) => (
                        <Description key={index}>{paragraph}</Description>
                    ))}
                </ContentContainer>
                <InfoContainer $hide={isHidden}>
                    <Information>
                        <Row>
                            <SubTitleSmall>Contributeurs</SubTitleSmall>
                            <Col>
                                {project.contributeurs?.map((contributor, index) => (
                                    <Item key={index}>{contributor}</Item>
                                ))}
                            </Col>
                        </Row>
                    </Information>
                    <Information>
                        <Row>
                            <SubTitleSmall>Type</SubTitleSmall>
                            <Item>{project.type}</Item>
                        </Row>
                        <Row>
                            <SubTitleSmall>Construction</SubTitleSmall>
                            <Item>{project.construction}</Item>
                        </Row>
                        <Row>
                            <SubTitleSmall>Année</SubTitleSmall>
                            <Item>{project.annee}</Item>
                        </Row>
                        <Row>
                            <SubTitleSmall>Localisation</SubTitleSmall>
                            <Item>{project.localisation}</Item>
                        </Row>
                        <Row>
                            <SubTitleSmall>Superficie </SubTitleSmall>
                            <Item>{project.superficie}</Item>
                        </Row>
                        <Row>
                            <SubTitleSmall>Photographie</SubTitleSmall>
                            <Item>{project.photographie}</Item>
                        </Row>
                    </Information>
                </InfoContainer>
            </Container>
        </>
    );
};

export default ProjectInformation;
