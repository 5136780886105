import React, { useState } from "react";
import { Helmet } from "react-helmet";
import useProjects from "../Assets/Hooks/getProjects";
import ProjectsGrid from "../Components/ProjectsGrid/ProjectsGrid";
import LoadingComponent from "../Components/Common/LoadingComponent/LoadingComponent";
import { SubTitle, Title } from "../Components/Common/Common.styles";
import PageIntro from "../Components/Common/PageIntro/PageIntro";
import Footer from "../Components/Footer/Footer";
import CategorySelector from "../Components/CategorySelector/CategorySelector";

export default function ProjectsPage() {
    const { projects, isLoading, error } = useProjects();
    const [selectedCategory, setSelectedCategory] = useState("Tous");

    if (isLoading) return <LoadingComponent />;
    if (error) return <p>Error loading job listings: {error.message}</p>;

    // Filter projects based on the selected category
    const filteredProjects = selectedCategory === "Tous"
        ? projects
        : projects.filter(project => project.categories.includes(selectedCategory));

    return (
        <>
            <Helmet>
                <title>GroupeLeclerc | Projets</title>
                <meta name='description' content="La page des projets présente une liste de tous les projets réalisé par le Groupe Leclerc" />
                <meta name='keywords' content="Architecture, Design, Développement, Emploi, Job, Offre, Stage, Projet, Recrutement" />
            </Helmet>

            <PageIntro>
                <Title>
                    Nos projets ''les meilleurs''
                </Title>
                <SubTitle>
                    comme les recettes de Ricardo!
                </SubTitle>
            </PageIntro>

            <CategorySelector projects={projects} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
            <ProjectsGrid projects={filteredProjects} />

            <Footer />
        </>
    );
}
