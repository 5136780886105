import styled from "styled-components";

export const ProjectImageContainer = styled.div`
width: 100%;
position: relative;
overflow: hidden;
`;

export const Image = styled.img`
    width: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 2/0.9;

    @media (max-width: 600px) {
        aspect-ratio: 1/1;
    }
`;