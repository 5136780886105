import styled from "styled-components";

export const FooterContainer = styled.div`
    width: 100%;
    margin-top: 5rem;
    background-color: #FFFFFF;
    color: #000000;
    padding: 2rem 0;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
`;

export const FooterContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    @media screen and (max-width: 580px) {
        flex-direction: column;
    }

    & > p{
        @media screen and (max-width: 580px) {
        font-size: 0.6rem !important;
        }
    }
`;

export const FooterLogo = styled.img`
    width: 100%;
    max-width: 200px;
    object-fit: contain;
`;

export const FooterIcons = styled.svg`
        height: 1.5rem;
        width: 1.5rem;
        color: #000000;
`;

export const ContactOptions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    @media screen and (max-width: 580px) {
        align-items: center;
        flex-direction: row;
    }
`;
