import { Helmet } from 'react-helmet';
import JobListings from "../Components/JobsListingComponent/JobsListing"
import PageIntro from '../Components/Common/PageIntro/PageIntro';
import { JustifiedText, RowContainer, Title } from '../Components/Common/Common.styles';
import Footer from '../Components/Footer/Footer';

import cherryImage from '../Assets/Images/Illustrations/Illustration_carriere.png';

export default function CareersPage() {
    return (
        <>
            <Helmet>
                <title>GroupeLeclerc | Carriѐres</title>
                <meta name='description' content="Offres d'emplois chez Groupe Leclerc" />
                <meta name='keywords' content="Architecture, Design, Développement, Emploi, Job, Offre, Stage, Projet, Recrutement" />
            </Helmet>
            <RowContainer>
            <PageIntro>
                <Title>
                    Parce que tu serais la cerise sur notre <i>sundae</i> !
                </Title>
                <br/>
                <JustifiedText>
                    Notre équipe se démarque par la diversité des atouts et des compétences de ses membres, d'autant plus
                    que par son climat de travail sympathique qu'elle cherche continuellement à parfaire. La collaboration,
                    l'entraide, l'adaptabilité et le plaisir sont des qualités précieuses de notre lieu de travail.
                    <br/><br/>
                    Est-ce qu'on est du genre tricoté serré? Un peu oui! 
                    Et en prime depuis 2022, nous avons adopté la semaine de quatre jours afin
                    de promouvoir le mieux-être et offrir à tous un meilleur équilibre travail-vie personnelle.
                </JustifiedText>
            </PageIntro>
            <img loading="lazy" src={cherryImage} alt="cherry"/>
            </RowContainer>
            <JobListings />

            <Footer />
        </>

    )
}