import JobItem from './JobItem';
import useJobsListing from '../../Assets/Hooks/getJobsListing';
import LoadingComponent from '../Common/LoadingComponent/LoadingComponent';

import { JobsListingContainer } from './JobsListing.styles';

export default function JobListings() {
  const { jobListings, isLoading, error } = useJobsListing();

  if (isLoading) return <LoadingComponent />;
  if (error) return <p>Error loading job listings: {error.message}</p>;
  return (
    <JobsListingContainer>
      {jobListings?.map(job => <JobItem key={job.titre} job={job} />)}
    </JobsListingContainer>
  );
};
