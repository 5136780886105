import styled from "styled-components";

export const CategorySelectorContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
`;

export const CategorySelectorItem = styled.div`
list-style: none;
font-size: 1rem;
font-weight: 400;
cursor: pointer;

&.selected {
    color: #020202;
    text-decoration: underline;
    text-underline-offset: 0.5rem;
    font-weight: 600;
}

&:hover, &:focus {
    color: #00000080;
    text-decoration: underline;
    text-underline-offset: 0.5rem;
    text-decoration-thickness: 0.1rem;
    text-decoration-color: #00000020;
}
`;