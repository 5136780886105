import { FourOFour } from "./404Component.styles"

export default function NotFound() {
    return (
        <FourOFour>
            <h1>404</h1>
            <p>Page non trouvée</p>
        </FourOFour>
    )
}
