import styled from "styled-components";

export const ImagesGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-auto-rows: minmax(100px, auto); // Ensures rows are sized based on content
    grid-gap: 10px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1200px) {
        grid-template-columns: repeat(7, 1fr);
    }

    @media (max-width: 930px) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: 600px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const ImageCard = styled.div`
    cursor: pointer;
    &:hover img {
        opacity: 0.8;
        transition: all 0.2s ease-in-out;
    }
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
    display: block;
    user-select: none;
    padding: 5px;
`;

export const Modal = styled.div`
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModalContent = styled.div`
    position: relative;
    overflow: hidden;

    & > img {
        display: block;
        width: auto; 
        max-height: 100vh;
        margin: auto;
    }
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    height: 40px;
    width: 40px;
    background-color: #020202;
    border-radius: 50%;
    border: none;
    font-size: 30px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
        height: 20px;
        width: 20px;
        stroke: #FFFFFF;
    }

    &:hover {
        cursor: pointer;
        color: #bbb;

        & svg {
            stroke: #FFFFFF;
            transition: all 0.2s ease-in-out;
            transform: scale(0.9);
        }
    }
`;
