import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: ${({ reverse }) => reverse ? "row-reverse" : "row"};
    justify-content: space-between;
    align-items: flex-start;
    margin: 35px auto;
    width: 100%;
    gap: 20px;

    @media (max-width: 930px) {
        max-width: 765px;
    }

    @media (max-width: 700px) {
        flex-direction: column;
    }
`;


export const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${({ reverse }) => reverse ? "flex-end" : "flex-start"};
    width: 20%;
    max-width: 200px;
    
    & h2 {
        font-weight: 600;
        font-size: 1rem;
    }

    @media (max-width: 700px) {
        width: 100%;
        align-items: left;
        max-width: 100%;
    }
`;

export const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 80%;

    & li {
        list-style: circle;
        margin-left: 15px;
    }

    @media (max-width: 700px) {
        width: 100%;
    }
`;

export const Image = styled.img`
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
`;