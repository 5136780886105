import { Row, Text } from "../Common/Common.styles";
import { FooterContainer, FooterContent, ContactOptions } from "./Footer.styles";
import { NavLink } from "react-router-dom";

const PhoneIcon = () => {
    return (
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 18.675c1.93.83 4.242 1.325 7 1.325v-4l-4-1-3 3.675Z"></path>
            <path d="M4 4c0 1.898.4 4.472 1.5 7L9 8 8 4H4Z"></path>
            <path d="M13 18.675C9.159 17.023 6.824 14.045 5.5 11"></path>
        </svg>
    )
}

const MailIcon = () => {
    return (
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="m7 9 3.75 3a2 2 0 0 0 2.5 0L17 9"></path>
            <path d="M21 7v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2Z"></path>
        </svg>
    )
}

const FacebookIcon = () => {
    return (
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
            <path d="M15.75 8.25h-1.5A2.241 2.241 0 0 0 12 10.5V21"></path>
            <path d="M9 13.5h6"></path>
        </svg>
    )
}

const InstagramIcon = () => {
    return (
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 15.188a3.188 3.188 0 1 0 0-6.376 3.188 3.188 0 0 0 0 6.376Z"></path>
            <path d="M16.5 3h-9A4.5 4.5 0 0 0 3 7.5v9A4.5 4.5 0 0 0 7.5 21h9a4.5 4.5 0 0 0 4.5-4.5v-9A4.5 4.5 0 0 0 16.5 3Z"></path>
            <path fill="currentColor" stroke="none" d="M16.875 8.625a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
        </svg>
    )
}

export default function Footer() {
    return (
        <FooterContainer>
            <FooterContent>
                <div>
                    <a href="https://maps.app.goo.gl/qHCwYfYzbjLxy8jJA">
                        <Text style={{height: "50px"}}>
                            5245 chemin de Chambly <br />
                            Longueuil (Québec), J3Y 3N5 <br /><br />
                        </Text>
                    </a>
                    <NavLink style={{ opacity: "0.5"}} to="/politique-de-confidentialite" reloadDocument={true}>Politique de confidentialité</NavLink>
                </div>
                <ContactOptions>
                    <a href="https://www.facebook.com/GroupeLeclercAD/"><Row style={{ alignItems: "start", justifyContent: "start" }}>{FacebookIcon()}<Text className="notOnMobile"></Text></Row></a>
                    <a href="https://www.instagram.com/groupe.leclerc"><Row style={{ alignItems: "start", justifyContent: "start" }}>{InstagramIcon()} <Text className="notOnMobile"></Text></Row></a>
                    <a href="tel:(450)433-4130"><Row style={{ alignItems: "start", justifyContent: "start" }}>{PhoneIcon()} <Text className="notOnMobile"></Text></Row></a>
                    <a href="mailto:info@groupeleclerc.ca"><Row style={{ alignItems: "start", justifyContent: "start" }}>{MailIcon()} <Text className="notOnMobile"></Text></Row> </a>
                </ContactOptions >
            </FooterContent >
        </FooterContainer >
    )
}
