import { useState, useEffect } from 'react';
import client from '../../sanityClient';

function useJobsListing() {
  const [jobListings, setJobListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    client
      .fetch('*[_type == "emploi"]')
      .then(data => {
        setJobListings(data);
        setTimeout(() => {
        setIsLoading(false);
        }, 1500);
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
      });
  }, []);

  return { jobListings, isLoading, error };
}

export default useJobsListing;
