import React, { useRef, useState, useEffect } from "react"
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { MapContainer, Map } from "./MapboxMap.styles"

mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_TOKEN}`;

export default function MapboxMap() {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, ] = useState(-73.4232664);
    const [lat, ] = useState(45.5045883);
    const [zoom, ] = useState(12);

    useEffect(() => {
        if (map.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/webcity/clp7nece300yd01nw0xqr3vuu',
            center: [lng, lat],
            zoom: zoom,
            pitch: 50,
            scrollZoom: false,
            touchZoomRotate: false,
            pitchWithRotate: false,
        });

        new mapboxgl.Marker({ color: "#c06044", draggable: false, anchor: "bottom" })
            .setLngLat([lng, lat])
            .addTo(map.current);

        // Add dragend event listener
        map.current.on('dragend', () => {
            map.current.flyTo({ center: [lng, lat], zoom: zoom });
        });

    }, [lat, lng, zoom]);

    // const handleMouseLeave = () => {
    //     if (!map.current) return;
    //     map.current.flyTo({ center: [lng, lat], zoom: zoom });
    // };

    return (
        <MapContainer>
            <Map ref={mapContainer} className="map-container" />
        </MapContainer>
    );
}