import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3rem 0;
    gap: 5rem;

    @media screen and (max-width: 930px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 0;
    }
`;


export const TitleContainer = styled.div`
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 930px) {
        padding: 0;
        display: ${props => props.$hide ? 'none' : 'block'};
        flex-direction: column;
        margin-bottom: 2rem;
    }
`;

export const Information = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ShowInfoButton = styled.button`
    display: none;
    background: #020202;
    color: white;
    border: none;
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    margin: 1rem 0;
    transition: all 0.2s ease-in-out;

    &:active {
        transform: scale(0.95);
    }

    @media screen and (max-width: 930px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const Item = styled.div`
font-size: 1rem;
font-weight: 400;
width: 195px;

@media screen and (max-width: 930px) {
    text-align: right;
}
`;

export const ContentContainer = styled.div`
display: flex;
flex-direction: column;
gap: 1rem;

@media screen and (max-width: 930px) {
    & h2 {
        font-size: 1.5rem !important;
    }
`;


export const Description = styled.p`
    font-size: 1rem;
    margin-bottom: 2rem;
    text-align: justify;
    max-width: 960px;
`;

export const PrimaryText = styled.p`
font-size: 1.1rem;
color: #7a7a7a;
max-width: 450px;
`;

export const SecondaryText = styled.p`
    font-size: 1.1rem;
    color: #7a7a7a;
`;

export const ProjectTitle = styled.h2`
    font-size: 2.5rem;
    font-weight: bold;
`;