import styled from "styled-components";

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  background-color: #000000;
  margin: 0.5rem;
  margin-inline: auto;
`;

export const Title = styled.h1`
  font-size: 1rem;
  font-weight: 500;


  @media screen and (max-width: 930px) {
    white-space: normal;
  }
`;

export const InfoTitle = styled.h2`
font-weight: 600;
font-size: 1rem;
white-space: nowrap;

@media screen and (max-width: 930px) {
  white-space: normal;
}
`;

export const SubTitle = styled.h3`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
`;

export const SubTitleSmall = styled.h4`
font-size: 1rem;
font-weight: 700;
margin-bottom: 0.5rem;
width: 150px;
`;

export const Text = styled.p`
  font-size: 1rem;
  font-weight: 400;
  `;

  export const SmallText = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 1rem;
  text-align: left;
  `;

export const JustifiedText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  text-align: justify;

  b {
    font-weight: 600;
  }
  `;

  export const SmallJustifiedText = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 1rem;
  text-align: justify;
  `;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  margin-top: 5px;

  & svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  @media screen and (max-width: 930px) {
        justify-content: space-between;
  }

  @media screen and (max-width: 580px) {
    & svg {
      height: 1.8rem;
      width: 1.8rem;
    }
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BubbleLink = styled.a`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  align-items: center;
  gap: 0.5rem;
  border: none;
  outline: none;
  background-color: transparent;
  color: #000000AA;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  width: max-content;

  &:hover, &:focus {
    scale: 1.05;
  }

  &:active {
   scale: 0.95;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;

  & img {
    height: 250px;
  }

  @media screen and (max-width: 930px) {
    flex-direction: column-reverse;
    align-items: center;

    & img {
      max-width: 100%;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  & p {
    font-size: 1rem;
    font-weight: 400;
  }

  & ul {
    list-style-type: circle;
    padding: 0 1rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }

  & li {
    margin-left: 1rem;
  }

  & a {
    color: #000000BB;
    font-style: italic;
    text-decoration: underline;
  }

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;