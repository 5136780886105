import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    
    @media (max-width: 930px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 680px) {
        grid-template-columns: 1fr;
    }
`;

export const ContactCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3rem;
    min-height: 110px;

    & ContactIcon {
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0.8rem !important;

        &:active {
            transform: scale(1) translateX(-50%);
          }
    }
    `;

export const ContactIcon = styled.div`
    position: absolute;
    top: -17.5px;
    left: 50%;
    transform: translateX(-50%);
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 3px;
    background-color: #FFFFFF;

    & svg {
        height: 35px;
        width: 35px;
        stroke: #000;
    }

    @media (max-width: 480px) {
        & svg {
        height: 35px;
        width: 35px;
        }
    }
`;

export const ContactCardTitle = styled.h4`
    font-size: 1rem;
    margin-top: 2rem;
    text-align: center;
    letter-spacing: -0.5px;
`;

export const ContactCardText = styled.p`
    font-size: 1rem;
    height: 6rem;
`;
