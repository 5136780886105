import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HeaderContainer, HeaderLogo, HeaderNav, NavItems, MobileMenu } from "./Header.styles";
import logo from "../../Assets/Images/logo.png";

export default function Header() {
    const [isHidden, setIsHidden] = useState(false);
    return (
        <HeaderContainer>
            <MobileMenu onClick={() => { setIsHidden(!isHidden) }}>
                {isHidden ? 
                <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41Z"></path>
                </svg> :
                    <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M3 8V6h18v2H3Zm0 5h18v-2H3v2Zm0 5h18v-2H3v2Z" clipRule="evenodd"></path>
                    </svg>
                }
            </MobileMenu>
            <NavLink to="/">
                <HeaderLogo src={logo} alt="Groupe Leclerc" />
            </NavLink>
            <HeaderNav $hide={isHidden}>
                <NavItems onClick={()=>setIsHidden(false)}>
                    <NavLink to="/" activeclassname="active">Accueil</NavLink>
                </NavItems>
                <NavItems onClick={()=>setIsHidden(false)}>
                    <NavLink to="/notre-firme" activeclassname="active">Firme</NavLink>
                </NavItems>
                <NavItems onClick={()=>setIsHidden(false)}>
                    <NavLink to="/projets" activeclassname="active">Projets</NavLink>
                </NavItems>
                <NavItems onClick={()=>setIsHidden(false)}>
                    <NavLink to="/emplois" activeclassname="active">Carrières</NavLink>
                </NavItems>
                <NavItems onClick={()=>setIsHidden(false)}>
                    <NavLink to="/contact" activeclassname="active">Contact</NavLink>
                </NavItems>
            </HeaderNav>
        </HeaderContainer>
    )
}
