import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Image, ImageTitle, SlideItem, ArrowButton, Container, ImageContainer } from './ProjectsSlider.styles';
import { InfoTitle, Separator } from '../Common/Common.styles';
import { Link } from 'react-router-dom';

export default function ImageSlider({ data }) {


  const ArrowLeft = () => (
    <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M19.92 12.48a.6.6 0 0 0-.6-.6H5.17l3.776-3.775a.601.601 0 0 0-.85-.85l-4.8 4.8a.6.6 0 0 0 0 .85l4.8 4.8a.602.602 0 0 0 .98-.655.6.6 0 0 0-.13-.195L5.169 13.08H19.32a.6.6 0 0 0 .6-.6Z" clipRule="evenodd"></path>
    </svg>
  );

  const ArrowRight = () => (
    <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M3.12 12.48a.6.6 0 0 1 .6-.6h14.152l-3.777-3.775a.6.6 0 1 1 .85-.85l4.8 4.8a.6.6 0 0 1 0 .85l-4.8 4.8a.6.6 0 1 1-.85-.85l3.777-3.775H3.72a.6.6 0 0 1-.6-.6Z" clipRule="evenodd"></path>
    </svg>
  );

  return (
    <Container>
      <InfoTitle>Nos projets</InfoTitle>
      <Separator />
      <Carousel
        className='carousel'
        swipeable={true}
        draggable={true}  
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="transform 500ms ease-in-out"
        transitionDuration={500}
        itemClass='carousel-item'
        centerMode={window.innerWidth > 600 ? true : false}
        responsive={{
          superLargeDesktop: {
            breakpoint: { max: 3000, min: 1801 },
            items: 3,
          },
          desktop: {
            breakpoint: { max: 1800, min: 1025 },
            items: 3,
          },
          tablet: {
            breakpoint: { max: 1024, min: 600 },
            items: 1,
          },
          mobile: {
            breakpoint: { max: 600, min: 0 },
            items: 1,
          },
        }}

        customLeftArrow={<ArrowButton style={{ left: "15px" }}><ArrowLeft /></ArrowButton>}
        customRightArrow={<ArrowButton style={{ right: "15px" }}><ArrowRight /></ArrowButton>}
      >
        {data?.map((dataItem, index) => (
          <SlideItem key={index} style={{ willChange: 'transform' }}>
            <ImageContainer key={dataItem.id} as={Link} to={`/projet/${dataItem.slug}`}>
              <Image src={dataItem.imageUrl} alt={dataItem.title} />
              <ImageTitle>{dataItem.title}</ImageTitle>
            </ImageContainer>
          </SlideItem>
        ))}
      </Carousel>
    </Container>
  );
}
