import React, { useEffect, useState } from "react";
import { CategorySelectorContainer, CategorySelectorItem } from "./CategorySelector.styles";

export default function CategorySelector({ projects, setSelectedCategory, selectedCategory }) {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setCategories(["Tous", ...new Set(projects.map((project) => project.categories).flat())]);
    
    // Check if there is a "filter" query parameter in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const filterParam = urlParams.get("filter");
    
    if (filterParam) {
      // If "filter" is present in the URL, set the selected category based on the query parameter
      setSelectedCategory(decodeURIComponent(filterParam));
    } else {
      // If no "filter" in the URL, set the default category to "Tous"
      setSelectedCategory("Tous");
    }
  }, [projects]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    
    // Update the URL with the selected category as a query parameter
    window.history.pushState(null, "", `?filter=${encodeURIComponent(category)}`);
  };

  return (
    <CategorySelectorContainer>
      {categories.map((category, index) => (
        <CategorySelectorItem
          key={index}
          onClick={() => handleCategoryChange(category)}
          className={selectedCategory === category ? "selected" : ""}
        >
          {category}
        </CategorySelectorItem>
      ))}
    </CategorySelectorContainer>
  );
}
