import { Link } from "react-router-dom"
import { ProjectsGridContainer, ProjectCard, ProjectImage, ProjectTitle } from "./ProjectsGrid.styles"

export default function ProjectsGrid({ projects }) {


    return (
        <ProjectsGridContainer>
            {projects?.map((project, index) => (
                <ProjectCard key={project.id + "" + index} as={Link} to={`/projet/${project.slug}`}>
                    <ProjectImage src={project.imageUrl} alt={project.title} />
                    <ProjectTitle>{project.title}</ProjectTitle>
                </ProjectCard>
            ))}
        </ProjectsGridContainer>
    )
}